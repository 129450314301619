import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import to from 'await-to-js';
import { lastValueFrom } from 'rxjs';
import { PackService } from 'src/app/services/pack.service';
import { ProductsService } from 'src/app/services/products.service';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {
  @ViewChild('videoElement') videoElement!: ElementRef;
  @Input() card: any;
  @Input() canSkip: boolean;
  progressPercent: number = 0;

  showStopButton = true;
  showRewardButton = false;
  user: any = null;

  constructor(
    private packService: PackService,
    private utilsServices: UtilsService,
    private userService: UsersService,
    private productServices: ProductsService
  ) {}

  ngAfterViewInit() {
    this.user = this.userService.getLoggedUser();
    const video = this.videoElement.nativeElement;

    video.play();

    video.addEventListener('timeupdate', () => {
      this.progressPercent = 100 * (video.currentTime / video.duration);
    });

    video.addEventListener('ended', () => {
      this.showStopButton = false;
      this.showRewardButton = true;
      this.utilsServices.dismissModal(true);
    });
  }

  playPauseVideo() {
    const video = this.videoElement.nativeElement;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  stopVideo() {
    this.showStopButton = false;
    this.utilsServices.dismissModal();
  }

  async buyPackAdvertisment(): Promise<number> {
    const product = {
      packtemplate_id: 99,
      user_id: this.user.id,
      price: 0,
      quantity: 1,
    };

    const [_, response] = await to<any, any>(
      lastValueFrom(this.productServices.pay(product))
    );

    return response.data.id;
  }

  async closeVideo() {
    this.showRewardButton = false;
    const packId = await this.buyPackAdvertisment();

    const payload = {
      cardId: this.card.code,
      teamId: this.user.teams[0].id,
      packId,
    };
    const [_, response] = await to(
      lastValueFrom(this.packService.useAvailableContract(payload))
    );
    this.utilsServices.dismissModal(response);
  }

  startMatch() {
    this.utilsServices.dismissModal();
  }
}
